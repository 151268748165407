import axios from "axios";

import { getEnvValue } from "./get-env-value";

export const orgAuthPath = (path = ""): string => {
  return `${getEnvValue("RAILS_RELATIVE_URL_ROOT") || ""}${path}`;
};

export interface Configuration {
  type: "configurations";
  data: ConfigurationData;
}

interface ConfigurationData {
  attributes: ConfigurationDataAttributes;
}

export interface ConfigurationDataAttributes {
  client_id: string;
  org_api_configurations_url: string;
  oidc_well_known_url: string;
  redirect_url: string;
  sign_in_url: string;
  forgot_password_url: string;
}

let cachedConfig: ConfigurationDataAttributes | null = null;

/**
 * Gets the config from the server needed to log in
 */
export async function getConfig(): Promise<ConfigurationDataAttributes> {
  if (cachedConfig) {
    return Promise.resolve(cachedConfig);
  }

  try {
    const response = await axios.get<Configuration>(
      orgAuthPath("/api/v2/configurations")
    );

    // we're purposefully not doing ?. because
    // we want it to fail if the config is missing
    cachedConfig = response.data.data.attributes;

    return cachedConfig;
  } catch (e: unknown) {
    console.error(e);

    throw new Error("Failed to get config");
  }
}

export interface OrgApiMessages {
  data: OrgApiMessagesData[];
}

interface OrgApiMessagesData {
  attributes: OrgApiMessagesDataAttributes;
}

interface OrgApiMessagesDataAttributes {
  message: string;
}

const cachedMessage: { fetched: boolean; text: string | null } = {
  fetched: false,
  text: null,
};

export async function getMaintenanceMessage(
  configurationsUrl: string
): Promise<string | null> {
  if (cachedMessage.fetched) {
    return Promise.resolve(cachedMessage.text);
  }
  try {
    const response = await axios.get<OrgApiMessages>(configurationsUrl);

    cachedMessage.fetched = true;
    cachedMessage.text = response.data.data?.[0]?.attributes?.message || null;
    return cachedMessage.text;
  } catch (e: unknown) {
    console.error(e);

    return null;
  }
}
