export type Key = "decision-lab" | "org-admin" | "org-lab" | "talent-match";

interface AppConfig {
  id: Key;
  name: string;
  contact: string;
  colors: Colors;
}

export interface Colors {
  primary: string;
  hover: string;
  disabled: string;
  theme?: string;
}

const apps: Record<Key, AppConfig> = {
  "org-lab": {
    id: "org-lab",
    name: "OrgLab",
    contact: "mailto: orglab_support@mckinsey.com",
    colors: {
      primary: "#8dc63f",
      hover: "#9bda45",
      disabled: "#7a9950",
    },
  },
  "org-admin": {
    id: "org-admin",
    name: "OrgAdmin",
    contact: "mailto: orglab_support@mckinsey.com",
    colors: {
      primary: "#2980b9",
      hover: "#1a5175",
      disabled: "#133c58",
    },
  },
  "decision-lab": {
    id: "decision-lab",
    name: "DecisionLab",
    contact: "mailto: decisionlab-support@mckinsey.com",
    colors: {
      primary: "#00B8D4",
      hover: "#008094",
      disabled: "#949494",
    },
  },
  "talent-match": {
    id: "talent-match",
    name: "TalentMatch",
    contact: "https://mckinseynvt.secure.force.com/mckinseysupport",
    colors: {
      primary: "#034458",
      hover: "#067699",
      disabled: "#949494",
    },
  },
};

const APP_KEY = "ORG_AUTH_APP_ID";

export function identifyApp(): AppConfig {
  const id = new URLSearchParams(window.location.search).get("app");

  if (id !== null && apps[id as Key]) {
    sessionStorage[APP_KEY] = id;
  }
  return apps[sessionStorage[APP_KEY] as Key] || apps["org-lab"];
}

export function clearApp(): void {
  sessionStorage.removeItem(APP_KEY);
}
