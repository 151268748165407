import { orgAuthPath } from "./api";

const DESTINATION_KEY = "ORG_AUTH_DESTINATION";
const EMAIL_KEY = "ORG_AUTH_EMAIL";

export const isEmailAddress = (s: string | null | undefined): boolean => {
  return !!s && /^\S+@(\S+\.)+\S+$/i.test(s);
};

export const callBackURL = (path: string): string => {
  return new URL(orgAuthPath(path), window.location.href).href;
};

/* Destination Redirect Helpers */
const destinationRegex = (): RegExp => {
  switch (window.location.hostname) {
    case "auth.orglabsolutions.com":
    case "auth-uat.orglabsolutions.com":
    case "auth-us.orglabsolutions.com":
      return /^((([a-z0-9\-_]+\.)+orglabsolutions\.com)|solutions\.mckinsey\.com)$/i;
    default:
      return /^((([a-z0-9\-_]+\.)+orglabsolutions\.com)|(([a-z0-9\-_]+\.)+mckinsey\.(com|cloud))|localhost)$/i;
  }
};

const destinationIsValid = (destination: string): boolean => {
  try {
    const url = new URL(destination);
    if (destinationRegex().test(url.hostname)) {
      return true;
    } else {
      console.error(`Invalid destination: ${destination}`);
    }
  } catch (error: unknown) {
    console.error(`Invalid destination: ${destination}`, error);
  }

  return false;
};

export const setFromSearch = (
  key: string,
  name: string,
  validate: (value: string) => boolean
): string | null => {
  const value = new URLSearchParams(window.location.search).get(name);
  const valueIsValid = !!value && validate(value);

  if (valueIsValid) {
    sessionStorage.setItem(key, value);

    return value;
  }

  return sessionStorage.getItem(key);
};

export const setEmail = (): string | null => {
  return setFromSearch(EMAIL_KEY, "email", isEmailAddress);
};

export const setDestinationUri = (): string | null => {
  return setFromSearch(DESTINATION_KEY, "destination", destinationIsValid);
};

export const getValue = (key: string): string | null => {
  return sessionStorage.getItem(key);
};

export const getDestinationUri = (): string | null => {
  return getValue(DESTINATION_KEY);
};

export const getEmail = (): string | null => {
  return getValue(EMAIL_KEY);
};

export const clearValue = (key: string): void => {
  sessionStorage.removeItem(key);
};

export const clearDestinationUri = (): void => {
  clearValue(DESTINATION_KEY);
};

export const clearEmail = (): void => {
  clearValue(EMAIL_KEY);
};

export const missingDestinationMessage = `Sorry we have lost track of which
  application you are trying to sign in to.
  Please return to the application and try again.`;

export const redirectToDestinationUri = (
  destination: string,
  jwt: string
): void => {
  if (!destinationIsValid(destination)) {
    throw new Error(`Invalid destination URL: ${destination}`);
  }
  const url = new URL(destination);
  url.hash = `jwt=${jwt}`;
  window.location.href = url.toString();
};

export const parseFragment = (fragment: string): Record<string, string> => {
  return fragment
    .split("&")
    .reduce((acc: Record<string, string>, current: string) => {
      // CAST SAFETY:
      // the only way we end up with [] is when we do randomString.split("something", 0)
      // omitting the second param or anything > 0 yields at least the original item:
      // "".split(anything, 1) yields = [""]
      const [k, v] = current.split("=") as [string, string | undefined];
      acc[decodeURIComponent(k)] = decodeURIComponent(v ?? "");
      return acc;
    }, {});
};
